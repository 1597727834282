"use client";
import { IMAGE_SIZES } from "@/constants";
import { convertImageURL } from "@/utils/common";
import Image from "next/image";

export default function CloudFlareImage(props) {
  const loader = () => cloudFlareLoader(props);
  if (props.src) {
    return <Image skipNoscript={true} loader={loader} {...props} />;
  } else {
    return <img></img>;
  }
}

export const cloudFlareLoader = ({ src, width, quality, alt, cfsize }) => {
  if (!src) return "";
  let convertURL = "";
  // CF Image, keep the original src url
  if (src.startsWith(process.env.NEXT_PUBLIC_CF_LOADER_URL)) {
    convertURL = src;
  } else {
    convertURL = convertImageURL(src);
  }
  if (isNaN(width) || src.includes(".svg") || src.includes("cdn.stamped.io")) return convertURL;
  const q = quality || 85;
  return buildImgURL(convertURL, width, q, alt, cfsize);
};

const buildImgURL = (url, width, quality, name, cfsize) => {
  let finalURL = ``;
  // Cloud flare image
  if (url.startsWith(process.env.NEXT_PUBLIC_CF_LOADER_URL)) {
    finalURL = url;
    // If there is image size being assigned, format url
    if (cfsize) {
      if (finalURL?.endsWith(IMAGE_SIZES.PUBLIC))
        finalURL = finalURL.substring(0, finalURL.length - IMAGE_SIZES.PUBLIC.length - 1);
      finalURL = finalURL + "/";
      switch (cfsize) {
        case IMAGE_SIZES.THUMBNAIL:
          return (finalURL = finalURL + IMAGE_SIZES.THUMBNAIL);
        case IMAGE_SIZES.MEDIUM:
          return (finalURL = finalURL + IMAGE_SIZES.MEDIUM);
        case IMAGE_SIZES.LARGE:
          return (finalURL = finalURL + IMAGE_SIZES.LARGE);
        default:
          return finalURL + IMAGE_SIZES.PUBLIC;
      }
    }
    return finalURL;
  }

  // Other types of image from other sources
  const base64URL = Buffer.from(url).toString("base64").replace(/\//g, "-");
  finalURL = `${process.env.NEXT_PUBLIC_CF_LOADER_URL}/${width}-${quality}/${base64URL}`;
  // Firebase image
  if (url.startsWith("https://firebasestorage.googleapis.com") || url.startsWith("https://images.prismic.io")) {
    let imgName = name ? name.replace(/\s|\//g, "-").replace(/[^\w-]+/g, "") : "image";
    const urlObj = new URL(url);
    const extension = urlObj.pathname.substring(urlObj.pathname.lastIndexOf(".")).toLowerCase();
    if ([".jpg", ".png", ".jpeg", ".gif"].includes(extension)) {
      imgName += extension;
    }
    finalURL += `/${imgName}`;
    return finalURL;
  }

  // Default
  return finalURL;
};
